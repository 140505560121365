<template>
  <div>
    <back-button to="/admin/">Back to Settings</back-button>
    <div class="avo-header-text">Data Management</div>

    <b-row class="m-3">
      <b-col cols="4">
        <b-form-input
          id="filter-input"
          v-model="filterSearchText"
          type="search"
          placeholder="Type to Search"
        ></b-form-input>
      </b-col>
      <b-col />
      <b-col cols="1">
        <b-button
          @click="saveMapping"
          variant="primary"
          class="m-2 avo-primary-btn"
          >Save mapping</b-button
        >
      </b-col>
    </b-row>

    <unmatched-payers-table
      v-model="allscripts_payers"
      :filter="filterSearchText"
      carrier_key="Carrier_ID"
      carrier_key_label="Carrier ID"
      pm_system="Allscripts"
      :loading="loading"
      />
    <unmatched-payers-table
      v-model="ecw_payers"
      :filter="filterSearchText"
      carrier_key="insId"
      carrier_key_label="Insurance_ID"
      pm_system="eClinicalWorks EBO"
      :loading="loading"
      />
  </div>
</template>


<script>
import { make_url, authenticated_request } from "../common";
import errorStore from "../errorStore";
import UnmatchedPayersTable from './UnmatchedPayersTable.vue';
import BackButton from "../components/BackButton.vue"

export default {
  name: "Data",
  components: { 
    UnmatchedPayersTable,
    BackButton 
  },
  data() {
    return {
      filterSearchText: "",
      allscripts_payers: [],
      ecw_payers: [],
      loading: true,
    };
  },
  methods: {
    getUnmatchedPayers: function () {
      const url = make_url("/tank/payers");

      this.loading = true;

      const self = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
      })
        .then(function (response) {
          if (response) {
            const data = response.data;
            for (const datum of response.data["allscripts_unmatched_payers"]) {
              datum.target_ref = null;
              datum.flag_bad_address = false;
            }
            for (const datum of response.data["ecw_unmatched_payers"]) {
              datum.target_ref = null;
              datum.flag_bad_address = false;
            }
            self.allscripts_payers = response.data["allscripts_unmatched_payers"];
            self.ecw_payers = response.data["ecw_unmatched_payers"];
          }
        })
        .finally(function () {
          self.loading = false;
        });
    },
    saveMapping: function () {
      const url = make_url("/tank/payers");
      const payer_mapping = [...this.ecw_payers, ...this.allscripts_payers]

      const promise_array = [];

      for (const item of payer_mapping) {
        if (item.target_ref) {
            const data = {
              practice_id: item.practice_id,
              source_ref: item.insId || item.Carrier_ID,
              target_ref: item.target_ref.payer_id,
              flag_bad_address: item.flag_bad_address,
            };
            const promise = authenticated_request({
              method: "put",
              url: url,
              data: data,
            }, (error) => { throw error; });
            promise_array.push(promise);
        }
      }

      const mapping_promise = Promise.allSettled(promise_array);

      const current_router_instance = this.$router;
      // If all promises are successful, then reload the page, otherwise show an error message
      mapping_promise.then(function (results) {
        const failures = results.filter(function (result) {
          return result.status === "rejected";
        });

        if (failures.length > 0) {
          errorStore.commit("displayAlerts", failures.map(item => item.reason.response.data.msg));
          console.log("Failed mappings: ", failures.map(item => item.reason));
        } else {
          current_router_instance.go();
        }
      });
    },
  },
  mounted() {
    this.getUnmatchedPayers();
  }
};
</script>