<template>
    <div @click="onClick()" class="back-btn">
        <i class="fas fa-chevron-left mr-2"></i>
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'BackButton',
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        history: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        onClick() {
            this.$emit('click');
            if (this.to) {
                this.$router.push(this.to);
            } 
            else if (this.history) {
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style scoped>
.back-btn {
    font-size: 14px;
    margin-top:-15px;
    margin-bottom:20px;
    color: #38455D;
    cursor: pointer;
}
</style>