<template>
<div>
    <div class="table-header-text">
      <b-row>
        <b-col> Unmatched {{pm_system}} Payers </b-col>
      </b-row>
    </div>
    <b-table
      :per-page="20"
      :current-page="currentPage"
      :items="value"
      :fields="fields"
      :filter="filter"
      @filtered="onFiltered($event)"
      sort-icon-left
      show-empty
      :empty-text="'No unmatched payers for ' + pm_system"
      empty-filtered-text="Nothing here matches your search"
      :busy="loading"
    >
        <template #cell(flag_bad_address)="data">
          <b-form-group>
            <b-form-checkbox v-model="data.item.flag_bad_address">
              Bad address
            </b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(last_failed_claim_date)="data">
          <span v-if="data.item.last_failed_claim_date">
            {{formatDateShort(data.item.last_failed_claim_date)}}
          </span>
        </template>
        <template #cell(kebab)="data">
          <payer-multiselect
            :key="data.item.Carrier_ID"
            v-model="data.item.target_ref"
            :multiple="false"
          />
        </template>
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="total_rows"
      :per-page="20"
      v-if="total_rows > 0"
      align="center"
    />
</div>
</template>


<script>
import { formatDateShort } from "./../common.js"
import PayerMultiselect from "../components/multiselects/PayerMultiselect.vue"

export default {
  name: "UnmatchedPayersTable",
  props: {
    value: {
        type: Array,
        required: true
    },
    filter: {
        type: String,
        required: false
    },
    carrier_key: {
        type: String,
        required: true
    },
    carrier_key_label: {
        type: String,
        required: true
    },
    pm_system: {
        type: String,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
  },
  components: { PayerMultiselect },
  data() {
    return {
      total_rows: 0,
      currentPage: 1,
      fields: [
        {
          key: this.carrier_key,
          label: this.carrier_key_label,
          sortable: true,
        },
        {
          key: "key",
          label: "Key",
          sortable: true,
        },
        {
          key: "practice_id",
          label: "Practice ID",
          sortable: true,
        },
        {
          key: "flag_bad_address",
          label: "Flag Bad Address",
        },
        {
          key: "failed_claims_count",
          label: "# Failed Claims",
          sortable: true,
        },
        {
          key: "last_failed_claim_date",
          label: "Last Failed Claim Date",
          sortable: true,
        },
        {
          key: "kebab",
          label: "Match to a payer",
        },
      ],
    };
  },
  methods: {
    onFiltered: function (e) {
      this.total_rows = e.length;
    },
    formatDateShort: function (datetime) {
      return formatDateShort(datetime);
    },
  },
  watch: {
      value: function (newVal) {
        this.total_rows = newVal.length;
      },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.data-header-text {
  margin-right: 30px;
  font-weight: 300;
  font-size: 36px;
  color: var(--dark);
}

.table-header-text {
  margin-right: 30px;
  font-weight: 200;
  font-size: 22px;
  color: var(--dark);
}

::v-deep .multiselect__spinner {
  background: #dde4f5 !important;
}
::v-deep .invalid .multiselect__tags {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
</style>