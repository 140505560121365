<template>
    <multiselect
        openDirection="bottom"
        placeholder="Search by payer name or address" 
        :custom-label="payerLabel"
        v-model="selectedPayer"
        track-by="payer_id"
        :options="selectablePayers"
        :loading="payersLoading"
        :internal-search="false"
        :close-on-select="!multiple"
        :multiple="multiple"
        :allow-empty="allowEmpty"
        :show-labels="false"
        @search-change="debouncePayers"
        :showNoOptions="true">
        <template slot="noResult">
            No payers matched your search
        </template>
        <template slot="noOptions">
            Start typing a payer name or address...
        </template>
    </multiselect>
</template>

<script>
import { make_url, authenticated_request, debounce } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'PayerMultiselect',
  components: { 
      Multiselect,
  },
  props: {
      value: [Object, Array],
      multiple: {
          type: Boolean,
          default: false,
      },
      allowEmpty: {
          type: Boolean,
          default: true,
      },
  },
  data: function(){
      return {
          selectablePayers: [],
          selectedPayer: null,
          payerSearchText: "",
          payersLoading: false,
      }
  },
  methods: {
    payerSearchChange: function(searchQuery) {
        this.payersLoading = true;
        this.payerSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.payerAutocomplete();
        } else {
            this.payersLoading = false;
        }
    },
    payerAutocomplete() {
        var vm = this;
        const url = make_url("/reports/payers/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.payerSearchText,
                limit: 10
            }
        }).then(function(response){
            vm.payersLoading = false;
            const payers = response.data.payers;
            vm.selectablePayers = payers || [];
            return payers || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    payerLabel(payer){
        if (payer.full_address){
            return (payer.name + ' - ' + payer.full_address)
        } else {
            return payer.name
        }
    },
  },
  watch: {
      selectedPayer() {
          this.$emit('input', this.selectedPayer)
      },
      value: {
          deep: true,
          immediate: true,
          handler: function(newValue) {
            if (newValue) {
                this.selectedPayer = newValue;
                if (this.multiple) {
                    newValue.map((option) => this.selectablePayers.push(option));
                }
                else {
                    this.selectablePayers.push(newValue);
                }
            }
          }
      },
  },
    created(){
        this.debouncePayers = debounce(this.payerSearchChange, 300)
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
</style>